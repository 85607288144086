import { SearchBarOptions, SearchRouteName, SearchUrlPart } from '@ecom/stores/search'
import { useSearch } from '@ecom/composables/useSearch/useSearch'

export default defineNuxtRouteMiddleware((to) => {
  const {
    searchBarSavedOptions,
    searchBarOptionsList,
    selectedSearchBarOption,
    wasSearchPageAccessedOnSSR,
  } = useSearch()

  const { localePath } = useT3Utils()
  if (
    to.query?.option
    && typeof to.query.option === 'string'
    && Object.values(SearchBarOptions).includes(searchBarSavedOptions.value.selectedOption.value)
    && typeof to.name === 'string'
    && to.name.includes('search')
  ) {
    const foundOption = searchBarOptionsList.value.find(option => option.value === to.query.option)

    if (foundOption && wasSearchPageAccessedOnSSR.value) {
      selectedSearchBarOption.value = foundOption
    }
  }
  if (to.name === SearchRouteName.MAIN && to.query.term) {
    return navigateTo(localePath(`search/${SearchUrlPart.STORE}`))
  }

  if (
    (to.name === SearchRouteName.MAIN
    || to.name === SearchRouteName.STORE
    || to.name === SearchRouteName.CMS)
    && !to.query.term
  ) {
    return navigateTo(localePath())
  }
})
